import CheckBox from '@uikit/components/CheckBox/CheckBox';
import clsx from 'clsx';
import MapExpandToggle from '../MapExpandToggle/MapExpandToggle';
import styles from './EegWindparkMapFilter.module.scss';
import type { EegWindparkMapFilterProps } from './types';

const EegWindparkMapFilter = ({
  handleChange,
  toggleVisible,
  isVisible,
  activeFilters,
  filterCriteria,
}: EegWindparkMapFilterProps) => {
  return (
    <div
      className={clsx(styles.base, {
        [styles.visible]: isVisible,
      })}
    >
      <div className={styles.wrapper}>
        <MapExpandToggle
          className={styles.toggle}
          expanded={isVisible}
          handleClick={toggleVisible}
        />

        {filterCriteria.map((criteria) => (
          <label
            key={criteria.label}
            className={clsx(styles.label, styles[criteria.type], {
              [styles.active]: activeFilters.includes(criteria.type),
            })}
          >
            <CheckBox
              className={styles.checkbox}
              onChange={() => handleChange(criteria.type)}
              checked={activeFilters.includes(criteria.type)}
            />
            {criteria.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default EegWindparkMapFilter;
