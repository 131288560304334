import { MapMarkerProps } from '@uikit/components/MapMarker/interfaces';
import { Point } from 'points-cluster';
import { MARKER_TYPES } from './consts';
import { CustomLocation } from './types';

const pointToMapMarkerProps = (
  point: Point<CustomLocation>,
  detailsLocation?: CustomLocation
):
  | (MapMarkerProps & { key: string; underConstruction?: boolean })
  | undefined => {
  const location = point.points?.[0].location;

  if (!location) {
    return;
  }

  return {
    key: `place-${point.lat}-${point.lng}_${location.name}`,
    lat: point.lat,
    lng: point.lng,
    isCurrentMarker: detailsLocation?.id === location?.id,
    color: MARKER_TYPES[location.type].color,
    icon: MARKER_TYPES[location.type].icon,
    underConstruction: location.underConstruction,
  };
};

export default pointToMapMarkerProps;
