import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { formatNumber } from '@uikit/helpers/formatNumber';
import { useId } from 'react';
import styles from './EegNumberTicker.module.scss';
import type { EegNumberTickerProps } from './types';

const EegNumberTickerDesktop = ({
  windparks,
  windplants,
  generatedPower,
  households,
}: EegNumberTickerProps) => {
  const id = useId();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1165 920" fill="none">
      <path
        stroke="#FFEB99"
        strokeWidth={8}
        d="M956.5 590c0 74.5-11.5 225.5-172 234-100.5 0-178-56.044-178-211.5"
        className={styles.cordPower}
      />
      <path
        stroke="#99D7DA"
        strokeWidth={8}
        d="M303 311.5c0 167.5-8 348 64.5 387s127.5-28 127.5-89"
        className={styles.cordWindplants}
      />
      <path
        stroke="#C2E1AB"
        strokeWidth={8}
        d="M168 741.5c0 80 43.5 163.547 145 173.5 98.527 9.661 238-62.5 238-285.5"
        className={styles.cordWindparks}
      />
      <g filter={`url(#${id}-a)`} className={styles.balloonPower}>
        <rect width={320} height={320} x={797} y={282} fill="#FC0" rx={160} />
        <mask
          id={`${id}-b`}
          width={320}
          height={320}
          x={797}
          y={282}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={320}
            height={320}
            x={797}
            y={282}
            fill="#D9D9D9"
            rx={160}
          />
        </mask>
        <g mask={`url(#${id}-b)`}>
          <text
            xmlSpace="preserve"
            fill="#fff"
            fontFamily="Euclid Enercity"
            fontSize={44}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={957} y={474.104}>
              {generatedPower}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fill="#fff"
            fontFamily="Euclid Enercity"
            fontSize={20}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={910.5} y={505.32}>
              {'Megawatt'}
            </tspan>
          </text>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M903.516 458.277 856.2 522.361c-1.6 2.32-4.12 3.64-6.796 3.64-.84 0-1.724-.124-2.564-.4-3.56-1.12-5.84-4.28-5.84-8.08v-39.524h-31.356c-3.44 0-6.4-1.68-7.8-4.36-1.32-2.48-1.076-5.44.64-7.92l47.316-64.084c2.08-3.036 5.8-4.356 9.36-3.24 3.56 1.124 5.84 4.284 5.84 8.08v39.524h31.356c3.44 0 6.4 1.68 7.8 4.36 1.32 2.48 1.08 5.44-.64 7.92"
            clipRule="evenodd"
            opacity={0.3}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M925.415 315.906 898.8 351.953c-.9 1.305-2.317 2.048-3.823 2.048-.472 0-.969-.07-1.442-.225-2.002-.63-3.285-2.408-3.285-4.545v-22.233h-17.638c-1.935 0-3.6-.945-4.387-2.452-.743-1.395-.605-3.06.36-4.455l26.615-36.047a4.638 4.638 0 0 1 5.265-1.823c2.003.632 3.285 2.41 3.285 4.545v22.232h17.638c1.935 0 3.6.945 4.387 2.453.743 1.395.608 3.06-.36 4.455M1058.57 404.673 1029 444.726c-1 1.45-2.57 2.275-4.25 2.275-.52 0-1.07-.078-1.6-.25-2.22-.7-3.65-2.675-3.65-5.05v-24.703h-19.597c-2.15 0-4-1.05-4.875-2.725-.825-1.55-.673-3.4.4-4.95L1025 369.271a5.155 5.155 0 0 1 5.85-2.025c2.23.702 3.65 2.677 3.65 5.05v24.702h19.6c2.15 0 4 1.05 4.87 2.725.83 1.55.68 3.4-.4 4.95"
            clipRule="evenodd"
            opacity={0.2}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m1029.26 548.139-23.66 32.042c-.8 1.16-2.06 1.82-3.4 1.82-.42 0-.86-.062-1.28-.2-1.78-.56-2.92-2.14-2.92-4.04v-19.762h-15.678c-1.72 0-3.2-.84-3.9-2.18-.66-1.24-.538-2.72.32-3.96l23.658-32.042a4.123 4.123 0 0 1 4.68-1.62c1.78.562 2.92 2.142 2.92 4.04v19.762h15.68c1.72 0 3.2.84 3.9 2.18.66 1.24.54 2.72-.32 3.96"
            clipRule="evenodd"
            opacity={0.3}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M975.944 396.604 958.2 420.635c-.6.87-1.545 1.365-2.548 1.365-.315 0-.647-.046-.962-.15-1.335-.42-2.19-1.605-2.19-3.03v-14.821h-11.758c-1.29 0-2.4-.63-2.925-1.635-.495-.93-.404-2.04.24-2.97l17.743-24.032a3.094 3.094 0 0 1 3.51-1.215c1.335.422 2.19 1.607 2.19 3.03v14.822h11.759c1.29 0 2.4.63 2.925 1.635.495.93.405 2.04-.24 2.97"
            clipRule="evenodd"
          />
        </g>
      </g>
      <path
        fill="#FC0"
        d="M937 599h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentPower}
      />
      <g filter={`url(#${id}-c)`} className={styles.balloonWindplants}>
        <rect width={280} height={280} x={163} y={32} fill="#009AA3" rx={140} />
        <mask
          id={`${id}-d`}
          width={280}
          height={280}
          x={163}
          y={32}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={280}
            height={280}
            x={163}
            y={32}
            fill="#D9D9D9"
            rx={140}
          />
        </mask>
        <g fill="#fff" mask={`url(#${id}-d)`}>
          <path
            fillRule="evenodd"
            d="m322.198 128.82-14.544-8.398a5.29 5.29 0 0 0 1.035-1.964l3.01.618 10.74 9.326-.241.418Zm-20.957-11.794a2.338 2.338 0 0 1 2.334-2.334 2.338 2.338 0 0 1 2.336 2.334 2.338 2.338 0 0 1-2.336 2.332 2.337 2.337 0 0 1-2.334-2.332Zm-3.506 6.01-13.444 4.638-.242-.418 14.538-8.394a5.34 5.34 0 0 0 1.179 1.887l-2.031 2.287ZM304.478 95v16.783a5.275 5.275 0 0 0-.903-.091c-.457 0-.892.075-1.315.183l-.971-2.912L303.997 95h.481Zm20.055 31.248a22.816 22.816 0 0 0-2.392-22.647 1.499 1.499 0 1 0-2.432 1.756 19.829 19.829 0 0 1 2.436 18.816l-2.607-2.262c.764-2.497.969-5.142.521-7.729a1.503 1.503 0 0 0-1.734-1.221 1.498 1.498 0 0 0-1.221 1.734 13.717 13.717 0 0 1-.087 5.026l-3.648-3.168a1.487 1.487 0 0 0-.681-.336l-4.064-.834a5.293 5.293 0 0 0-1.146-1.966V93.5c0-.83-.67-1.5-1.5-1.5h-3.219c-.718 0-1.336.51-1.471 1.215l-.204 1.05a22.81 22.81 0 0 0-18.417 13.395 1.5 1.5 0 0 0 2.737 1.227 19.827 19.827 0 0 1 15.077-11.519l-.657 3.39c-2.543.587-4.935 1.73-6.953 3.411a1.505 1.505 0 0 0-.192 2.115 1.503 1.503 0 0 0 2.114.192 13.68 13.68 0 0 1 4.395-2.437l-.918 4.74c-.05.253-.033.513.048.76l1.309 3.933a5.283 5.283 0 0 0-1.119 1.971l-17.257 9.965a1.5 1.5 0 0 0-.549 2.049l1.609 2.788a1.499 1.499 0 0 0 1.79.669l1.006-.348a22.799 22.799 0 0 0 18.447 9.371c.785 0 1.574-.039 2.364-.12a1.502 1.502 0 0 0 1.341-1.646c-.085-.823-.838-1.413-1.645-1.339-6.788.687-13.316-2.082-17.513-7.298l3.263-1.126c1.78 1.908 3.966 3.406 6.429 4.315a1.5 1.5 0 0 0 1.038-2.815 13.73 13.73 0 0 1-4.31-2.589l4.566-1.575c.243-.083.461-.23.633-.422l2.739-3.085c.36.076.732.121 1.116.121a5.38 5.38 0 0 0 1.184-.141l17.238 9.951a1.511 1.511 0 0 0 1.138.15c.384-.102.713-.354.911-.699l1.611-2.788a1.501 1.501 0 0 0-.317-1.883l-.807-.7Z"
            clipRule="evenodd"
          />
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={44}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={303} y={192.104}>
              {windplants}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={20}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={241.193} y={223.32}>
              {'Windenergie-'}
            </tspan>
            <tspan x={264.758} y={247.32}>
              {'anlagen'}
            </tspan>
          </text>
          <g opacity={0.2}>
            <path d="M205.5 224.5h-35a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM195.5 264.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M228 234.5h-57.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5H228c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
          <g opacity={0.1}>
            <path d="M345.5 174.5h-21c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h21c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5ZM339.5 198.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5h-15c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h15c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5Z" />
            <path d="M359 180.5h-34.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5H359c1.65 0 3-1.35 3-3s-1.35-3-3-3c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c3.315 0 6 2.685 6 6s-2.685 6-6 6Z" />
          </g>
          <g opacity={0.05}>
            <path d="M197 105h-28c-1.1 0-2-.9-2-2s.9-2 2-2h28c3.3 0 6-2.7 6-6s-2.7-6-6-6c-1.1 0-2-.9-2-2s.9-2 2-2c5.52 0 10 4.48 10 10s-4.48 10-10 10ZM189 137c-1.1 0-2-.9-2-2s.9-2 2-2c3.3 0 6-2.7 6-6s-2.7-6-6-6h-20c-1.1 0-2-.9-2-2s.9-2 2-2h20c5.52 0 10 4.48 10 10s-4.48 10-10 10Z" />
            <path d="M215 113h-46c-1.1 0-2-.9-2-2s.9-2 2-2h46c2.2 0 4-1.8 4-4s-1.8-4-4-4c-1.1 0-2-.9-2-2s.9-2 2-2c4.42 0 8 3.58 8 8s-3.58 8-8 8Z" />
          </g>
          <g opacity={0.05}>
            <path d="M370.5 64.5h-35A2.507 2.507 0 0 1 333 62c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5A2.507 2.507 0 0 1 368 42c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM360.5 104.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25A2.507 2.507 0 0 1 333 82c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M393 74.5h-57.5A2.507 2.507 0 0 1 333 72c0-1.375 1.125-2.5 2.5-2.5H393c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
        </g>
      </g>
      <path
        fill="#009AA3"
        d="M283 310h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentWindplants}
      />
      <g filter={`url(#${id}-e)`} className={styles.balloonWindparks}>
        <rect width={240} height={240} x={48} y={505} fill="#64B32C" rx={120} />
        <mask
          id={`${id}-f`}
          width={240}
          height={240}
          x={48}
          y={505}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={240}
            height={240}
            x={48}
            y={505}
            fill="#D9D9D9"
            rx={120}
          />
        </mask>
        <g fill="#fff" mask={`url(#${id}-f)`}>
          <path d="m189.51 587.39-5.835-5.28a1.576 1.576 0 0 0-.915-.39l-2.25-.135V572c0-.765-.585-1.41-1.35-1.485-.75-.075-1.47.435-1.62 1.185l-1.635 7.71c-.075.33-.03.675.12.975l1.02 2.04-8.28 4.8c-.66.39-.93 1.2-.615 1.905a1.488 1.488 0 0 0 1.83.81l7.275-2.355-.555 14.43h-14.76l-1.335-26.76 9.45 5.565c.24.135.495.21.765.21.465 0 .915-.21 1.2-.6.45-.615.375-1.485-.195-1.995l-7.305-6.72a1.445 1.445 0 0 0-.93-.39l-3.18-.195V558.5c0-.765-.585-1.41-1.35-1.5-.78-.06-1.47.435-1.62 1.185l-2.055 9.81c-.075.33-.03.675.12.975l1.44 2.94-10.71 6.3c-.66.39-.93 1.215-.6 1.905.255.555.795.885 1.365.885.15 0 .315-.03.465-.075l9.36-3.09c.165-.06.315-.135.465-.255L156.075 602H150c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5h36c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5h-4.935l-.63-16.44 7.32 4.23c.24.135.495.195.75.195a1.5 1.5 0 0 0 1.215-.615 1.5 1.5 0 0 0-.21-1.995v.015Z" />
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={44}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={168} y={657.104}>
              {windparks}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={20}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={119.25} y={688.32}>
              {'Windparks'}
            </tspan>
          </text>
          <g opacity={0.2}>
            <path d="M90.5 697.5h-35A2.507 2.507 0 0 1 53 695c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5A2.507 2.507 0 0 1 88 675c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM80.5 737.5A2.507 2.507 0 0 1 78 735c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25A2.507 2.507 0 0 1 53 715c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M113 707.5H55.5A2.507 2.507 0 0 1 53 705c0-1.375 1.125-2.5 2.5-2.5H113c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
          <g opacity={0.1}>
            <path d="M230.5 647.5h-21c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h21c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5ZM224.5 671.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5h-15c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h15c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5Z" />
            <path d="M244 653.5h-34.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5H244c1.65 0 3-1.35 3-3s-1.35-3-3-3c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c3.315 0 6 2.685 6 6s-2.685 6-6 6Z" />
          </g>
          <g opacity={0.05}>
            <path d="M82 578H54c-1.1 0-2-.9-2-2s.9-2 2-2h28c3.3 0 6-2.7 6-6s-2.7-6-6-6c-1.1 0-2-.9-2-2s.9-2 2-2c5.52 0 10 4.48 10 10s-4.48 10-10 10ZM74 610c-1.1 0-2-.9-2-2s.9-2 2-2c3.3 0 6-2.7 6-6s-2.7-6-6-6H54c-1.1 0-2-.9-2-2s.9-2 2-2h20c5.52 0 10 4.48 10 10s-4.48 10-10 10Z" />
            <path d="M100 586H54c-1.1 0-2-.9-2-2s.9-2 2-2h46c2.2 0 4-1.8 4-4s-1.8-4-4-4c-1.1 0-2-.9-2-2s.9-2 2-2c4.42 0 8 3.58 8 8s-3.58 8-8 8Z" />
          </g>
          <g opacity={0.05}>
            <path d="M255.5 537.5h-35a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM245.5 577.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M278 547.5h-57.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5H278c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
        </g>
      </g>
      <path
        fill="#64B32C"
        d="M148 743h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentWindparks}
      />
      <g filter={`url(#${id}-g)`}>
        <rect width={360} height={360} x={371} y={294} fill="#fff" rx={180} />
        <g clipPath={`url(#${id}-h)`}>
          <path
            fill={`url(#${id}-i)`}
            d="M513.608 364.289h-15.031v-2.144h15.041v2.144h-.01Zm4.299-6.443h-23.618v10.731h23.618v-10.731Z"
          />
          <path
            fill={`url(#${id}-j)`}
            d="M635.979 426.291c-1.144-.237-2.02-.66-2.639-1.278-1.33-1.32-2.01-5.041-2.01-10.133 0-15.503 5.041-29.883 7.33-29.883 2.289 0 7.34 14.782 7.34 30.285 0 9.7-2.031 11.04-5.732 11.205v-8.556h-4.289v8.36Zm-25.762 0c-1.145-.237-2.021-.66-2.64-1.278-1.33-1.32-2.01-5.041-2.01-10.133 0-15.503 5.041-29.883 7.33-29.883 2.289 0 7.34 14.782 7.34 30.285 0 9.7-2.02 11.04-5.732 11.205v-8.556h-4.288v8.36Zm-25.753 19.554h-49.371V424.93c0-9.473-7.701-17.193-17.175-17.193-9.475 0-17.176 7.71-17.176 17.193v20.915H490.01v-49.488l4.516-2.526 14.804-8.256 27.907-15.565 47.227 26.336v49.499Zm-53.67 0h-25.763V424.93c0-7.112 5.773-12.895 12.876-12.895s12.877 5.783 12.877 12.895v20.915h.01Zm-27.918-77.011h6.444v11.834l-6.444 3.587v-15.431.01Zm-42.928 57.457c-1.144-.237-2.02-.66-2.639-1.278-1.33-1.32-2.01-5.041-2.01-10.133 0-15.503 5.041-29.883 7.33-29.883 2.289 0 7.34 14.782 7.34 30.285 0 9.7-2.021 11.04-5.732 11.205v-8.556h-4.289v8.36Zm180.32 19.554v-15.049c5.588-.238 10.031-2.495 10.031-15.442 0-10.102-3.34-34.779-11.629-34.779s-11.629 24.348-11.629 34.449c0 6.536 1.011 10.721 3.279 12.978 1.629 1.618 3.659 2.35 5.659 2.649v15.204h-21.463v-15.059c5.587-.238 10.02-2.495 10.02-15.442 0-10.102-3.34-34.779-11.629-34.779-8.288 0-11.629 24.348-11.629 34.449 0 6.536 1.011 10.721 3.279 12.978 1.629 1.618 3.66 2.35 5.66 2.649v15.204h-21.464v-47.107l8.917 4.969 2.083-3.742-62.526-34.862-23.619 13.163v-13.74h-15.031v22.111l-23.886 13.317 2.093 3.742 8.917-4.968v47.097h-21.464v-15.05c5.588-.237 10.021-2.494 10.021-15.441 0-10.102-3.34-34.779-11.629-34.779S451 404.923 451 415.035c0 6.535 1.01 10.72 3.278 12.977 1.629 1.619 3.66 2.351 5.66 2.649v15.205h-8.577v4.278H651v-4.289h-10.732v-.01Z"
          />
          <path
            fill={`url(#${id}-k)`}
            d="M569.433 415.715h-23.608v-4.206h23.608v4.206Zm0 12.967h-23.608v-8.679h23.608v8.679Zm4.289-10.741v-10.73h-32.196v25.759h32.196v-15.029Z"
          />
        </g>
        <text
          xmlSpace="preserve"
          fill="#000"
          fontFamily="Euclid Enercity"
          fontSize={44}
          fontWeight="bold"
          letterSpacing="0em"
          textAnchor="middle"
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={551} y={526.258}>
            {formatNumber(households)}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          fill="#000"
          fontFamily="Euclid Enercity"
          fontSize={20}
          letterSpacing="0em"
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={457.768} y={561.474}>
            {'insgesamt versorgte'}
          </tspan>
          <tspan x={503.686} y={585.474}>
            {'Haushalte'}
          </tspan>
        </text>
      </g>
      <defs>
        <filter
          id={`${id}-a`}
          width={416}
          height={416}
          x={749}
          y={250}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_371"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_371"
            result="effect2_dropShadow_1627_371"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_371"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-c`}
          width={376}
          height={376}
          x={115}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_371"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_371"
            result="effect2_dropShadow_1627_371"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_371"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-e`}
          width={336}
          height={336}
          x={0}
          y={473}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_371"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_371"
            result="effect2_dropShadow_1627_371"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_371"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-g`}
          width={456}
          height={456}
          x={323}
          y={262}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_371"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_371"
            result="effect2_dropShadow_1627_371"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_371"
            result="shape"
          />
        </filter>
        <linearGradient
          id={`${id}-i`}
          x1={494.289}
          x2={517.907}
          y1={363.217}
          y2={363.217}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id={`${id}-j`}
          x1={451}
          x2={651}
          y1={407.345}
          y2={407.345}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id={`${id}-k`}
          x1={541.516}
          x2={573.722}
          y1={420.096}
          y2={420.096}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <clipPath id={`${id}-h`}>
          <path fill="#fff" d="M451 357.846h200v92.308H451z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const EegNumberTickerMobile = ({
  generatedPower,
  windparks,
  windplants,
  households,
}: EegNumberTickerProps) => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 1097" fill="none">
      <path
        stroke="#C2E1AB"
        strokeWidth={8}
        d="M120.5 644.5c0 72 11 78.6 41 135"
        className={styles.cordWindparks}
      />
      <path
        stroke="#FFEB99"
        strokeWidth={8}
        d="M253.5 436c0 72 12 240.5-25.5 308"
        className={styles.cordPower}
      />
      <path
        stroke="#99D7DA"
        strokeWidth={8}
        d="M119.5 244.5c-19 206 85 256 65 502.5"
        className={styles.cordWindplants}
      />
      <g filter={`url(#${id}-a)`} className={styles.balloonWindplants}>
        <rect width={200} height={200} x={20} y={32} fill="#009AA3" rx={100} />
        <mask
          id={`${id}-b`}
          width={200}
          height={200}
          x={20}
          y={32}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={200}
            height={200}
            x={20}
            y={32}
            fill="#D9D9D9"
            rx={100}
          />
        </mask>
        <g fill="#fff" mask={`url(#${id}-b)`}>
          <path
            fillRule="evenodd"
            d="m135.998 95.684-12.12-7a4.396 4.396 0 0 0 .862-1.635l2.509.515 8.95 7.77-.201.35Zm-17.464-9.83c0-1.072.874-1.944 1.945-1.944 1.072 0 1.946.872 1.946 1.945a1.948 1.948 0 0 1-1.946 1.944 1.948 1.948 0 0 1-1.945-1.944Zm-2.921 5.01-11.204 3.865-.201-.35 12.115-6.994c.22.592.548 1.13.982 1.572l-1.692 1.907Zm5.618-23.364v13.986a4.397 4.397 0 0 0-.752-.076 4.4 4.4 0 0 0-1.096.152l-.809-2.426L120.83 67.5h.401Zm16.713 26.04a19.014 19.014 0 0 0-1.994-18.873 1.25 1.25 0 1 0-2.026 1.464 16.526 16.526 0 0 1 2.03 15.68l-2.173-1.885c.637-2.081.808-4.285.434-6.441a1.248 1.248 0 1 0-2.462.428 11.43 11.43 0 0 1-.073 4.188l-3.04-2.64a1.23 1.23 0 0 0-.567-.28l-3.387-.695a4.41 4.41 0 0 0-.955-1.639V66.25c0-.691-.558-1.25-1.25-1.25h-2.682c-.599 0-1.114.425-1.226 1.012l-.17.875a19.008 19.008 0 0 0-15.348 11.163 1.25 1.25 0 0 0 2.281 1.022 16.523 16.523 0 0 1 12.564-9.598l-.547 2.825c-2.119.488-4.113 1.44-5.794 2.842a1.254 1.254 0 0 0-.16 1.763 1.253 1.253 0 0 0 1.761.16 11.395 11.395 0 0 1 3.663-2.032l-.765 3.95c-.042.212-.028.428.04.634l1.091 3.278a4.4 4.4 0 0 0-.933 1.642l-14.381 8.304a1.25 1.25 0 0 0-.457 1.707l1.341 2.324a1.249 1.249 0 0 0 1.491.558l.839-.29a18.998 18.998 0 0 0 15.372 7.808c.654 0 1.312-.032 1.97-.1a1.25 1.25 0 0 0 1.118-1.371 1.266 1.266 0 0 0-1.371-1.116c-5.657.572-11.097-1.735-14.594-6.081l2.719-.94c1.483 1.59 3.305 2.84 5.357 3.597a1.256 1.256 0 0 0 1.606-.741 1.25 1.25 0 0 0-.741-1.605 11.433 11.433 0 0 1-3.591-2.158l3.805-1.312a1.25 1.25 0 0 0 .527-.351l2.283-2.572c.3.064.61.102.93.102.34 0 .667-.047.986-.118l14.365 8.293a1.262 1.262 0 0 0 .949.125c.32-.085.594-.295.759-.583l1.342-2.324a1.249 1.249 0 0 0-.264-1.568l-.672-.584Z"
            clipRule="evenodd"
          />
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={36}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={120} y={149.176}>
              {windplants}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={16}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={70.555} y={174.856}>
              {'Windenergie-'}
            </tspan>
            <tspan x={89.406} y={194.856}>
              {'anlagen'}
            </tspan>
          </text>
          <g opacity={0.2}>
            <path d="M66.5 199.5h-35A2.507 2.507 0 0 1 29 197c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5A2.507 2.507 0 0 1 64 177c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM56.5 239.5A2.507 2.507 0 0 1 54 237c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25A2.507 2.507 0 0 1 29 217c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M89 209.5H31.5A2.507 2.507 0 0 1 29 207c0-1.375 1.125-2.5 2.5-2.5H89c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
          <g opacity={0.1}>
            <path d="M202.5 170.5h-21c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h21c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5ZM196.5 194.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5h-15c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h15c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5Z" />
            <path d="M216 176.5h-34.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5H216c1.65 0 3-1.35 3-3s-1.35-3-3-3c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c3.315 0 6 2.685 6 6s-2.685 6-6 6Z" />
          </g>
          <g opacity={0.05}>
            <path d="M54 105H26c-1.1 0-2-.9-2-2s.9-2 2-2h28c3.3 0 6-2.7 6-6s-2.7-6-6-6c-1.1 0-2-.9-2-2s.9-2 2-2c5.52 0 10 4.48 10 10s-4.48 10-10 10ZM46 137c-1.1 0-2-.9-2-2s.9-2 2-2c3.3 0 6-2.7 6-6s-2.7-6-6-6H26c-1.1 0-2-.9-2-2s.9-2 2-2h20c5.52 0 10 4.48 10 10s-4.48 10-10 10Z" />
            <path d="M72 113H26c-1.1 0-2-.9-2-2s.9-2 2-2h46c2.2 0 4-1.8 4-4s-1.8-4-4-4c-1.1 0-2-.9-2-2s.9-2 2-2c4.42 0 8 3.58 8 8s-3.58 8-8 8Z" />
          </g>
          <g opacity={0.05}>
            <path d="M206.5 79.5h-35A2.507 2.507 0 0 1 169 77c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5A2.507 2.507 0 0 1 204 57c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM196.5 119.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25A2.507 2.507 0 0 1 169 97c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M229 89.5h-57.5A2.507 2.507 0 0 1 169 87c0-1.375 1.125-2.5 2.5-2.5H229c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
        </g>
      </g>
      <path
        fill="#009AA3"
        d="M100 230h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentWindplants}
      />
      <g filter={`url(#${id}-c)`} className={styles.balloonPower}>
        <rect width={200} height={200} x={155} y={230} fill="#FC0" rx={100} />
        <mask
          id={`${id}-d`}
          width={200}
          height={200}
          x={155}
          y={230}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={200}
            height={200}
            x={155}
            y={230}
            fill="#D9D9D9"
            rx={100}
          />
        </mask>
        <g mask={`url(#${id}-d)`}>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M270.786 291.837 256 311.863a2.574 2.574 0 0 1-2.925 1.012c-1.113-.35-1.825-1.337-1.825-2.525v-12.351h-9.799c-1.075 0-2-.525-2.438-1.362-.412-.775-.336-1.7.2-2.475L254 274.135a2.577 2.577 0 0 1 2.925-1.012c1.112.351 1.825 1.339 1.825 2.525v12.351h9.798c1.075 0 2 .525 2.438 1.363.412.775.337 1.7-.2 2.475Z"
            clipRule="evenodd"
          />
          <text
            xmlSpace="preserve"
            fill="#fff"
            fontFamily="Euclid Enercity"
            fontSize={36}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={255} y={357.176}>
              {generatedPower}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fill="#fff"
            fontFamily="Euclid Enercity"
            fontSize={16}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={217.5} y={382.856}>
              {'Megawatt'}
            </tspan>
          </text>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M250.516 373.277 203.2 437.361c-1.6 2.32-4.12 3.64-6.796 3.64-.84 0-1.724-.124-2.564-.4-3.56-1.12-5.84-4.28-5.84-8.08v-39.524h-31.356c-3.44 0-6.4-1.68-7.8-4.36-1.32-2.48-1.076-5.44.64-7.92l47.316-64.084c2.08-3.036 5.8-4.356 9.36-3.24 3.56 1.124 5.84 4.284 5.84 8.08v39.524h31.356c3.44 0 6.4 1.68 7.8 4.36 1.32 2.48 1.08 5.44-.64 7.92Z"
            clipRule="evenodd"
            opacity={0.3}
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M283.415 263.906 256.8 299.953c-.9 1.305-2.317 2.048-3.823 2.048-.472 0-.969-.07-1.442-.225-2.002-.63-3.285-2.408-3.285-4.545v-22.233h-17.638c-1.935 0-3.6-.945-4.387-2.452-.743-1.395-.605-3.06.36-4.455l26.615-36.047a4.638 4.638 0 0 1 5.265-1.823c2.003.632 3.285 2.41 3.285 4.545v22.232h17.638c1.935 0 3.6.945 4.387 2.453.743 1.395.608 3.06-.36 4.455ZM360.573 352.673 331 392.726c-1 1.45-2.575 2.275-4.247 2.275a5.194 5.194 0 0 1-1.603-.25c-2.225-.7-3.65-2.675-3.65-5.05v-24.703h-19.597c-2.15 0-4-1.05-4.875-2.725-.825-1.55-.673-3.4.4-4.95L327 317.271a5.152 5.152 0 0 1 5.85-2.025c2.225.702 3.65 2.677 3.65 5.05v24.702h19.598c2.15 0 4 1.05 4.875 2.725.825 1.55.675 3.4-.4 4.95Z"
            clipRule="evenodd"
            opacity={0.2}
          />
        </g>
      </g>
      <path
        fill="#FC0"
        d="M235 427h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentPower}
      />
      <g filter={`url(#${id}-e)`} className={styles.balloonAttachmentWindparks}>
        <rect width={200} height={200} x={20} y={430} fill="#64B32C" rx={100} />
        <mask
          id={`${id}-f`}
          width={200}
          height={200}
          x={20}
          y={430}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'alpha',
          }}
        >
          <rect
            width={200}
            height={200}
            x={20}
            y={430}
            fill="#D9D9D9"
            rx={100}
          />
        </mask>
        <g fill="#fff" mask={`url(#${id}-f)`}>
          <path d="m141.51 492.39-5.835-5.28a1.576 1.576 0 0 0-.915-.39l-2.25-.135V477c0-.765-.585-1.41-1.35-1.485-.75-.075-1.47.435-1.62 1.185l-1.635 7.71c-.075.33-.03.675.12.975l1.02 2.04-8.28 4.8c-.66.39-.93 1.2-.615 1.905a1.488 1.488 0 0 0 1.83.81l7.275-2.355-.555 14.43h-14.76l-1.335-26.76 9.45 5.565c.24.135.495.21.765.21.465 0 .915-.21 1.2-.6.45-.615.375-1.485-.195-1.995l-7.305-6.72a1.445 1.445 0 0 0-.93-.39l-3.18-.195V463.5c0-.765-.585-1.41-1.35-1.5-.78-.06-1.47.435-1.62 1.185l-2.055 9.81c-.075.33-.03.675.12.975l1.44 2.94-10.71 6.3c-.66.39-.93 1.215-.6 1.905.255.555.795.885 1.365.885.15 0 .315-.03.465-.075l9.36-3.09c.165-.06.315-.135.465-.255L108.075 507H102c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5h36c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5h-4.935l-.63-16.44 7.32 4.23c.24.135.495.195.75.195a1.5 1.5 0 0 0 1.215-.615 1.5 1.5 0 0 0-.21-1.995v.015Z" />
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={44}
            fontWeight="bold"
            letterSpacing="0em"
            textAnchor="middle"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={120} y={562.104}>
              {windparks}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            fontFamily="Euclid Enercity"
            fontSize={20}
            letterSpacing="0em"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <tspan x={71.25} y={593.32}>
              {'Windparks'}
            </tspan>
          </text>
          <g opacity={0.2}>
            <path d="M59.5 594.5h-35A2.507 2.507 0 0 1 22 592c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5A2.507 2.507 0 0 1 57 572c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM49.5 634.5A2.507 2.507 0 0 1 47 632c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25A2.507 2.507 0 0 1 22 612c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M82 604.5H24.5A2.507 2.507 0 0 1 22 602c0-1.375 1.125-2.5 2.5-2.5H82c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
          <g opacity={0.1}>
            <path d="M199.5 566.5h-21c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h21c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5ZM193.5 590.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c2.475 0 4.5-2.025 4.5-4.5s-2.025-4.5-4.5-4.5h-15c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5h15c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5Z" />
            <path d="M213 572.5h-34.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5H213c1.65 0 3-1.35 3-3s-1.35-3-3-3c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5c3.315 0 6 2.685 6 6s-2.685 6-6 6Z" />
          </g>
          <g opacity={0.05}>
            <path d="M56 499H28c-1.1 0-2-.9-2-2s.9-2 2-2h28c3.3 0 6-2.7 6-6s-2.7-6-6-6c-1.1 0-2-.9-2-2s.9-2 2-2c5.52 0 10 4.48 10 10s-4.48 10-10 10ZM48 531c-1.1 0-2-.9-2-2s.9-2 2-2c3.3 0 6-2.7 6-6s-2.7-6-6-6H28c-1.1 0-2-.9-2-2s.9-2 2-2h20c5.52 0 10 4.48 10 10s-4.48 10-10 10Z" />
            <path d="M74 507H28c-1.1 0-2-.9-2-2s.9-2 2-2h46c2.2 0 4-1.8 4-4s-1.8-4-4-4c-1.1 0-2-.9-2-2s.9-2 2-2c4.42 0 8 3.58 8 8s-3.58 8-8 8Z" />
          </g>
          <g opacity={0.05}>
            <path d="M209.5 477.5h-35a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h35c4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5ZM199.5 517.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 4.125 0 7.5-3.375 7.5-7.5s-3.375-7.5-7.5-7.5h-25a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h25c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5Z" />
            <path d="M232 487.5h-57.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5H232c2.75 0 5-2.25 5-5s-2.25-5-5-5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5 5.525 0 10 4.475 10 10s-4.475 10-10 10Z" />
          </g>
        </g>
      </g>
      <path
        fill="#64B32C"
        d="M100 628h40v4c0 11.046-8.954 20-20 20s-20-8.954-20-20v-4Z"
        className={styles.balloonAttachmentWindparks}
      />
      <g filter={`url(#${id}-g)`}>
        <rect width={335} height={335} x={20} y={714} fill="#fff" rx={167.5} />
        <g clipPath={`url(#${id}-h)`}>
          <path
            fill={`url(#${id}-i)`}
            d="M152.705 779.296h-13.987v-1.995h13.997v1.995h-.01Zm4.001-5.995h-21.978v9.986h21.978v-9.986Z"
          />
          <path
            fill={`url(#${id}-j)`}
            d="M266.578 836.993c-1.065-.221-1.88-.614-2.456-1.189-1.237-1.228-1.871-4.691-1.871-9.429 0-14.427 4.692-27.808 6.821-27.808 2.13 0 6.831 13.755 6.831 28.182 0 9.026-1.89 10.273-5.334 10.426v-7.961h-3.991v7.779Zm-23.974 0c-1.065-.221-1.88-.614-2.456-1.189-1.237-1.228-1.87-4.691-1.87-9.429 0-14.427 4.691-27.808 6.82-27.808 2.13 0 6.831 13.755 6.831 28.182 0 9.026-1.88 10.273-5.334 10.426v-7.961h-3.991v7.779Zm-23.964 18.196h-45.943v-19.462c0-8.815-7.166-16-15.982-16-8.816 0-15.983 7.175-15.983 16v19.462h-9.986v-46.052l4.201-2.35 13.777-7.683 25.969-14.484 43.947 24.508v46.061Zm-49.943 0h-23.974v-19.462c0-6.619 5.372-12 11.982-12 6.61 0 11.982 5.381 11.982 12v19.462h.01Zm-25.979-71.662h5.996v11.011l-5.996 3.338v-14.359.01Zm-39.947 53.466c-1.064-.221-1.88-.614-2.456-1.189-1.237-1.228-1.87-4.691-1.87-9.429 0-14.427 4.691-27.808 6.821-27.808 2.129 0 6.83 13.755 6.83 28.182 0 9.026-1.88 10.273-5.334 10.426v-7.961h-3.991v7.779Zm167.798 18.196v-14.004c5.199-.221 9.334-2.322 9.334-14.369 0-9.4-3.108-32.364-10.821-32.364-7.713 0-10.821 22.657-10.821 32.057 0 6.081.94 9.976 3.05 12.076 1.516 1.506 3.406 2.187 5.267 2.465v14.149h-19.973v-14.014c5.199-.221 9.324-2.322 9.324-14.369 0-9.4-3.108-32.364-10.821-32.364-7.713 0-10.821 22.657-10.821 32.057 0 6.081.94 9.976 3.05 12.076 1.516 1.506 3.406 2.187 5.267 2.465v14.149h-19.973v-43.836l8.298 4.623 1.938-3.482-58.184-32.44-21.978 12.249v-12.786h-13.987v20.575l-22.228 12.393 1.947 3.482 8.299-4.624v43.827h-19.974v-14.005c5.2-.22 9.325-2.321 9.325-14.369 0-9.4-3.108-32.364-10.821-32.364-7.713 0-10.822 22.667-10.822 32.076 0 6.082.94 9.976 3.051 12.077 1.516 1.506 3.406 2.187 5.267 2.465v14.148H94.78v3.981H280.555v-3.99h-9.986v-.01Z"
          />
          <path
            fill={`url(#${id}-k)`}
            d="M204.652 827.151h-21.969v-3.913h21.969v3.913Zm0 12.067h-21.969v-8.076h21.969v8.076Zm3.991-9.995v-9.985h-29.96v23.971h29.96v-13.986Z"
          />
        </g>
        <text
          xmlSpace="preserve"
          fill="#000"
          fontFamily="Euclid Enercity"
          fontSize={40.944}
          fontWeight="bold"
          letterSpacing="0em"
          textAnchor="middle"
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={187.5} y={929.962}>
            {formatNumber(households)}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          fill="#000"
          fontFamily="Euclid Enercity"
          fontSize={18.611}
          letterSpacing="0em"
          style={{
            whiteSpace: 'pre',
          }}
        >
          <tspan x={100.742} y={962.677}>
            {'insgesamt versorgte'}
          </tspan>
          <tspan x={143.471} y={985.01}>
            {'Haushalte'}
          </tspan>
        </text>
      </g>
      <defs>
        <filter
          id={`${id}-a`}
          width={296}
          height={296}
          x={-28}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_372"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_372"
            result="effect2_dropShadow_1627_372"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_372"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-c`}
          width={296}
          height={296}
          x={107}
          y={198}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_372"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_372"
            result="effect2_dropShadow_1627_372"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_372"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-e`}
          width={296}
          height={296}
          x={-28}
          y={398}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={16} />
          <feGaussianBlur stdDeviation={24} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_372"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_372"
            result="effect2_dropShadow_1627_372"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_372"
            result="shape"
          />
        </filter>
        <filter
          id={`${id}-g`}
          width={424.333}
          height={424.333}
          x={-24.667}
          y={684.222}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={14.889} />
          <feGaussianBlur stdDeviation={22.333} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1627_372"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.931} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_1627_372"
            result="effect2_dropShadow_1627_372"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1627_372"
            result="shape"
          />
        </filter>
        <linearGradient
          id={`${id}-i`}
          x1={134.728}
          x2={156.706}
          y1={778.299}
          y2={778.299}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id={`${id}-j`}
          x1={94.444}
          x2={280.555}
          y1={819.363}
          y2={819.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id={`${id}-k`}
          x1={178.673}
          x2={208.643}
          y1={831.228}
          y2={831.228}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#909" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <clipPath id={`${id}-h`}>
          <path fill="#fff" d="M94.444 773.301h186.111v85.897H94.445z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const EegNumberTicker = (props: EegNumberTickerProps) => {
  const isMobile = useIsMobile(576);

  const Component = isMobile ? EegNumberTickerMobile : EegNumberTickerDesktop;

  return <Component {...props} />;
};

export default EegNumberTicker;
