import Forms from '@/components/Forms/Forms';
import Container from '@uikit/components/Container/Container';
import EegFooter from '@uikit/components/EegFooter/EegFooter';
import EegHeader from '@uikit/components/EegHeader/EegHeader';
import EegModal from '@uikit/components/EegModal/EegModal';
import EegNumberTicker from '@uikit/components/EegNumberTicker/EegNumberTicker';
import EegWindparkMap from '@uikit/components/EegWindparkMap/EegWindparkMap';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';
import StageBoxWhite from '@uikit/components/StageBoxWhite/StageBoxWhite';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import React from 'react';

export const CmsComponentsEeg = {
  'eeg-footer': EegFooter,
  'eeg-header': EegHeader,
  'eeg-stage-box-text': StageBoxText,
  'eeg-stage-box-white': StageBoxWhite,
  'eeg-section': Section,
  'eeg-container': Container,
  'eeg-stage-small': SmallHeroArea,
  'eeg-stage': HeroArea,
  'eeg-stage-area': React.Fragment,
  'eeg-section-visual': SectionVisual,
  'eeg-two-column-container': TwoColumnContainer,
  'eeg-modal': EegModal,
  'eeg-windpark-map': EegWindparkMap,
  'eeg-iframe-section': IframeSection,
  'eeg-forms': Forms,
  'eeg-number-ticker': EegNumberTicker,
};
