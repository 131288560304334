import clsx from 'clsx';
import MapPin from '../MapPin/MapPin';
import styles from './MapClusterMarker.module.scss';
import type { MapClusterMarkerProps } from './interfaces';

const MapClusterMarker = ({
  amount,
  color = 'turquoise',
  onClick,
}: MapClusterMarkerProps) => {
  return (
    <div className={clsx(styles.base, styles[color])} onClick={onClick}>
      <span className={styles.span}>{amount}</span>
      <MapPin />
    </div>
  );
};

export default MapClusterMarker;
