import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { CustomLocation } from '@uikit/components/EegWindparkMap/types';
import { useQuery } from 'react-query';
import type { CmsNode } from '../../models/cms';
import { EEG_WINDPARKS_DATA_PATH } from './consts';

const getEegWindparks = async (): Promise<CustomLocation[]> => {
  try {
    const data =
      (await ajax.getJsonCached<readonly CmsNode[]>(EEG_WINDPARKS_DATA_PATH)) ??
      [];

    return data
      .filter(({ lat, lng }) => lat && lng)
      .map(
        ({ id, name, lat, lng, type, image, underConstruction, details }) => {
          return {
            id,
            name,
            lat,
            lng,
            type,
            image,
            underConstruction,
            details,
          };
        }
      );
  } catch (error) {
    logger.log('getEegWindparks', error);
    return [];
  }
};

export default function useEegWindparks() {
  return useQuery('eegWindparks', getEegWindparks);
}
