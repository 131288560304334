import React from 'react';
import type { EegWindparkMapFilterToggleProps } from './interfaces';
import Icon from '@uikit/components/Icon/Icon';
import Button from '@uikit/components/Button/Button';
import {
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@uikit/components/Button/consts';
import { ClickableAction } from '../Clickable/consts';
import styles from './EegWindparkMapFilterToggle.module.scss';

const EegWindparkMapFilterToggle = ({
  activeFilters,
  handleClick,
}: EegWindparkMapFilterToggleProps) => {
  return (
    <div className={styles.base}>
      <Button
        size={ButtonSize.Medium}
        color={ButtonColor.Secondary}
        actionType={ClickableAction.Custom}
        width={ButtonWidth.Full}
        onClick={handleClick}
        className={styles.filterToggle}
      >
        <Icon
          variant={'user/filter'}
          size="iconSize24"
          className={styles.filterIcon}
        />
        Windparks ({activeFilters.length})
      </Button>
    </div>
  );
};

export default EegWindparkMapFilterToggle;
