import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EegFooterProps } from './types';

const EegFooter = ({ skewOverlap }: EegFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity Erneuerbare GmbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/karriere">Karriere</NewFooter.Link>
          <NewFooter.Link href="/infothek">Infothek</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={54} project="eeg" solidFill />}
      skewOverlap={skewOverlap}
    />
  );
};

export default EegFooter;
