import useEegWindparks from '@/hooks/useEegWindparks/useEegWindparks';
import EegWindparkMapFilter from '@uikit/components/EegWindparkMapFilter/EegWindparkMapFilter';
import EegWindparkMapFilterToggle from '@uikit/components/EegWindparkMapFilterToggle/EegWindparkMapFilterToggle';
import EegWindparkMapTooltip from '@uikit/components/EegWindparkMapTooltip/EegWindparkMapTooltip';
import GoogleMap from '@uikit/components/GoogleMap/GoogleMap';
import MapClusterMarker from '@uikit/components/MapClusterMarker/MapClusterMarker';
import MapMarker from '@uikit/components/MapMarker/MapMarker';
import clsx from 'clsx';
import supercluster, { Cluster, Clusterer, Point } from 'points-cluster';
import { useEffect, useState } from 'react';
import styles from './EegWindparkMap.module.scss';
import * as MAP from './consts';
import pointToMapMarkerProps from './pointToMapMarkerProps';
import { CustomLocation, GeneralMapProps, LocationType } from './types';

const EegWindparkMap = () => {
  const [points, setPoints] = useState<Point<CustomLocation>[]>();
  const [mapProps, setMapProps] = useState<GeneralMapProps | undefined>();
  const [showFilter, setShowFilter] = useState(false);
  const { data: places } = useEegWindparks();
  const [activeLocationTypes, setActiveLocationTypes] = useState<
    LocationType[]
  >([...MAP.LOCATION_TYPES]);
  const [detailsLocation, setDetailsLocation] = useState<
    CustomLocation | undefined
  >();

  const filterCriteria = MAP.FILTER_CRITERIA.filter(
    (criteria) => places?.some((place) => place.type === criteria.type)
  );

  useEffect(() => {
    if (!mapProps || !places) {
      return;
    }

    const filteredPlaces = places.filter((place) =>
      activeLocationTypes.includes(place.type)
    );

    const getClusterer = (type: LocationType): Clusterer<CustomLocation> => {
      const placePoints: Point<CustomLocation>[] = filteredPlaces
        .filter((location) => location.type === type)
        .map((location) => ({
          lat: location.lat,
          lng: location.lng,
          location,
        }));

      return supercluster(placePoints, {
        radius: 60,
        minZoom: 3,
        maxZoom: 18,
      });
    };

    const clusterPoints: Point<CustomLocation>[] = MAP.LOCATION_TYPES.reduce(
      (clusterAccumulator: Cluster<CustomLocation>[], currentType) => {
        return [...clusterAccumulator, ...getClusterer(currentType)(mapProps)];
      },
      []
    ).map((props) => {
      const { wy, wx, numPoints, points } = props;

      return {
        lat: wy,
        lng: wx,
        numPoints,
        points,
      };
    });
    setPoints(clusterPoints);
  }, [mapProps, setPoints, places, activeLocationTypes]);

  const handleMarkerClick = (point: Point<CustomLocation>) => () => {
    const location = point.points?.[0].location;
    setDetailsLocation(location);

    setMapProps(
      (mapProps) =>
        mapProps && {
          ...mapProps,
          center: {
            lat: point.lat,
            lng: point.lng,
          },
        }
    );
  };

  const handleClusterClick = (point: Point<CustomLocation>) => () => {
    setMapProps(
      (mapProps) =>
        mapProps && {
          ...mapProps,
          center: { ...point },
          zoom: mapProps.zoom + 1,
        }
    );
  };

  const handleToggle = (filter: LocationType): void => {
    setDetailsLocation(undefined);

    setActiveLocationTypes((prevActiveLocationTypes) => {
      if (prevActiveLocationTypes.includes(filter)) {
        return prevActiveLocationTypes.filter((item) => item !== filter);
      }

      return [...prevActiveLocationTypes, filter];
    });
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className={styles.base}>
      <EegWindparkMapFilterToggle
        activeFilters={activeLocationTypes}
        handleClick={toggleFilter}
      />
      {filterCriteria.length > 0 && (
        <EegWindparkMapFilter
          handleChange={handleToggle}
          toggleVisible={toggleFilter}
          isVisible={showFilter}
          activeFilters={activeLocationTypes}
          filterCriteria={filterCriteria}
        />
      )}
      <GoogleMap
        defaultZoom={MAP.ZOOM_DEFAULT}
        zoom={mapProps?.zoom ?? MAP.ZOOM_DEFAULT}
        defaultCenter={MAP.CENTER_DEFAULT}
        center={mapProps?.center ?? MAP.CENTER_DEFAULT}
        bootstrapURLKeys={MAP.BOOTSTRAP_URL_KEYS}
        onChange={setMapProps}
        options={MAP.OPTIONS}
        yesIWantToUseGoogleMapApiInternals
        fullHeight
      >
        {points?.map((point) => {
          const location = point.points?.[0]?.location;

          if (!location || !point.numPoints) {
            return null;
          }

          if (point.numPoints === 1) {
            const props = pointToMapMarkerProps(point, detailsLocation);

            return (
              props && (
                <MapMarker
                  {...props}
                  className={clsx({
                    [styles.transparent]:
                      props.underConstruction && !props.isCurrentMarker,
                  })}
                  onClick={handleMarkerClick(point)}
                />
              )
            );
          }

          return (
            <MapClusterMarker
              key={`cluster-${point.lat}-${point.lng}`}
              amount={point.numPoints}
              onClick={handleClusterClick(point)}
              lat={point.lat}
              lng={point.lng}
              color={MAP.MARKER_TYPES[location.type].color}
            />
          );
        })}
      </GoogleMap>
      {detailsLocation && (
        <EegWindparkMapTooltip
          data={detailsLocation}
          handleClose={() => setDetailsLocation(undefined)}
        />
      )}
    </div>
  );
};

export default EegWindparkMap;
